<template>
  <div class="columns">
    <div class="column col-9 col-md-12">
      <h1>{{ $t("executive_search.intro_headline") }}</h1>
    </div>
    <div
      class="column col-7 col-md-12 executive__intro"
      v-html="$t('executive_search.intro')"
    ></div>
    <div class="column col-5 col-md-12">
      <img
        src="../../assets/images/service/services_executive-search_header_illustration.png"
        class="intro__image"
      />
    </div>
    <div class="column col-12">
      <h3 class="graphic-hl">{{ $t("executive_search.module_headline") }}</h3>
      <ul class="list--arrow">
        <li class="list__item">
          <span
            v-html="$t('executive_search.module_1')"
            class="list__item-inner"
          ></span>
        </li>
        <li class="list__item">
          <span
            v-html="$t('executive_search.module_2')"
            class="list__item-inner"
          ></span>
        </li>
        <li class="list__item">
          <span
            v-html="$t('executive_search.module_3')"
            class="list__item-inner"
          ></span>
        </li>
      </ul>
    </div>
    <div class="list__content">
      <div class="column col-5 col-md-12">
        <h2 class="h1">{{ $t("executive_search.statement_1_headline") }}</h2>
        <div v-html="$t('executive_search.statement_1_text')"></div>
      </div>
      <div class="column col-6 col-md-12 col-offset-1 col-md-offset-0">
        <div class="list--boxed list--boxed-left-bottom">
          <ul class="list__item list--dash">
            <li
              class="list__item"
              v-for="(point, index) in $t('executive_search.statement_1_list')"
              :key="index"
            >
              {{ point }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="list__content">
      <div class="column col-5 col-md-12">
        <h2 class="h1" v-html="$t('executive_search.statement_2_headline')" />
        <div v-html="$t('executive_search.statement_2_text')"></div>
      </div>
      <div class="column col-6 col-md-12 col-offset-1 col-md-offset-0">
        <div class="list--boxed list--boxed-left-bottom">
          <ul class="list__item list--dash">
            <li
              class="list__item"
              v-for="(point, index) in $t('executive_search.statement_2_list')"
              :key="index"
            >
              {{ point }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="list__content" :class="toggleSubline(3, 'list__content')">
      <div class="column col-5 col-md-12">
        <h2 class="h1" v-html="$t('executive_search.statement_3_headline')" />
        <div v-html="$t('executive_search.statement_3_text')"></div>
      </div>
      <div class="column col-6 col-md-12 col-offset-1 col-md-offset-0">
        <div class="list--boxed list--boxed-left-bottom">
          <ul class="list__item list--dash">
            <li
              class="list__item"
              v-for="(point, index) in $t('executive_search.statement_3_list')"
              :key="index"
            >
              {{ point }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="column column--full-width-bg">
      <div
        class="column col-6 col-sm-12 col-md-10 col-lg-9 col-xl-8 txt--center"
      >
        <h4>{{ $t("executive_search.link_headline") }}</h4>
        <router-link class="button button--arrow-left" to="/contact">{{
          $t("navigation.letstalk")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExecutiveSearch",
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    clickSubline: function(item) {
      if (this.selected == item) {
        this.selected = 0;
      } else {
        this.selected = item;
      }
    },
    toggleSubline: function(item, className) {
      if (this.selected == item) {
        return `${className}--visible`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column--full-width-bg {
  @include column--full-width-bg($color-primary-50);
}

.list--boxed {
  @include list--boxed;

  margin: 1rem 0 1rem;

  li {
    p {
      margin: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.list--boxed-left-bottom {
  @include list--boxed-left-bottom;
}

.list--dash {
  .list--boxed-left-bottom & {
    @media (min-width: $size-md) {
      margin-top: 2.5rem;
    }
  }

  @include list--dash;

  &.list__item {
    align-items: flex-start;
    flex-basis: 100%;
    text-align: left;
  }
}

.list--arrow {
  @include list--arrow;

  // @media (min-width: $size-md) {
  //   margin-bottom: 16vw;
  // }
}

.list {
  @include list;

  &__content {
    @media (min-width: $size-sm) {
      margin-bottom: 4rem;

      &:nth-child(7) {
        margin-bottom: 0;
      }
    }

    display: flex;
    flex-wrap: wrap;

    &--reverse {
      @media (min-width: $size-sm) {
        flex-direction: row;
      }

      flex-direction: column-reverse;
    }

    h3 {
      @media (min-width: $size-xl) {
        white-space: pre;
      }

      line-height: 1.25;
    }
  }
}

.intro {
  &__image {
    @media (min-width: $size-md) {
      transform: translate3d(-2em, -2em, 0);
      width: 125%;
      margin-bottom: 6vw;
    }

    object-fit: cover;
    width: 100%;
  }
}
</style>
